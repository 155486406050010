import {
  CountryCodes,
  CountryIds,
  IArrayResponse,
  ICreateStepValues,
  Platforms,
} from "core/models";
import { CampaignLocale } from "modules/Campaign/models";
import { ICancelReasonFormInput } from "modules/Payment/models";
import { IPerson } from "modules/Person/models";
import { ChipColors } from "ui-kit/atoms/Chip";

export enum AccountQuery {
  "account" = "account",
  "accounts" = "accounts",
  "new" = "account_new",
  "all" = "accounts_all",
  "exists" = "account_exists",
  "is_online" = "accounts_is_online",
  "last_log" = "account_last_log",
  "account_subscription" = "account_subscription",
}

export enum Days {
  "fri" = "fri",
  "mon" = "mon",
  "sat" = "sat",
  "sun" = "sun",
  "thu" = "thu",
  "tue" = "tue",
  "wed" = "wed",
}

export enum DayLabels {
  "fri" = "Friday",
  "mon" = "Monday",
  "sat" = "Saturday",
  "sun" = "Sunday",
  "thu" = "Thursday",
  "tue" = "Tuesday",
  "wed" = "Wednesday",
}

export enum ScrapingStatusValues {
  "available" = 1,
  "busy" = 2,
  "stopped" = 3,
  "restricted" = 4,
}

export enum ScrapingStatusLabels {
  "available" = "Available",
  "busy" = "Syncing",
  "stopped" = "Stopped",
  "restricted" = "Restricted",
}

export enum CreateAccountSteps {
  "account" = "account",
  "plan" = "plan",
  "country" = "country",
  "sync" = "sync",
}

export enum CreateAccountStepIndex {
  "account" = 0,
  "plan" = 1,
  "country" = 2,
  "sync" = 3,
}

export enum CreateAccountStepLabels {
  "account" = "Account",
  "plan" = "Plan",
  "country" = "Country",
  "sync" = "Finish",
}

export interface IWorkDay {
  active: boolean;
  end_time: string;
  start_time: string;
}

export interface IWorkTime {
  fri: IWorkDay;
  mon: IWorkDay;
  sat: IWorkDay;
  sun: IWorkDay;
  thu: IWorkDay;
  tue: IWorkDay;
  wed: IWorkDay;
}

export interface IAccountFormData {
  login: string;
  password: string;
  company: number | undefined;
  time_zone: TimeZones;
  platform: Platforms.linkedin;
  country: CountryCodes;
  price?: string;
}

export enum IAccountOnboardingIds {
  // // account_added = "account_added",
  // account_synced = "account_synced",
  campaign_created = "campaign_created",
  campaign_search = "campaign_search",
  campaign_sequence = "campaign_sequence",
  campaign_launched = "campaign_launched",
  // account_limits = "account_limits",
  // account_warmup = "account_warmup",
  // account_schedule = "account_schedule",
}

export enum IAccountOnboardingIdsToBox {
  // account_added = 0,
  // account_synced = 0,
  campaign_created = 1,
  campaign_search = 1,
  campaign_sequence = 1,
  campaign_launched = 1,
  // account_limits = 2,
  // account_warmup = 2,
  // account_schedule = 2,
}

export interface IAccountOnboarding {
  // [IAccountOnboardingIds.account_added]: boolean;
  // [IAccountOnboardingIds.account_synced]: boolean;
  [IAccountOnboardingIds.campaign_created]?: boolean;
  [IAccountOnboardingIds.campaign_search]?: boolean;
  [IAccountOnboardingIds.campaign_sequence]?: boolean;
  [IAccountOnboardingIds.campaign_launched]?: boolean;
  // [IAccountOnboardingIds.account_limits]: boolean;
  // [IAccountOnboardingIds.account_warmup]: boolean;
  // [IAccountOnboardingIds.account_schedule]: boolean;
}

export interface IAccountMetaData {
  meta_data: {
    options?: ICancelReasonFormInput["options"];
    reason?: ICancelReasonFormInput["reason"];
    inbox_privacy?: boolean;
    onboarding?: IAccountOnboarding;
    hide_onboarding?: boolean;
  };
}

export interface IAccountFormInput {
  login: string;
  password: string;
  company: {
    id: number;
    name: string;
  } | null;
  country: ICountryValues | null;
}

export interface IAccountFormValues {
  login: string;
  password: string;
  company: {
    id: number;
    name: string;
  } | null;
  time_zone: {
    id: TimeZones | string;
    name: string;
  };
  country: ICountryValues;
}

export interface IAccountPlaceholder {
  key: string;
  value: string;
}

export interface IAccountPlaceholders {
  [key: string]: string;
}

export interface SystemMetaData {
  recruiter_active: boolean;
  sales_nav_active: boolean;
}

export interface IAccountCurrent extends IAccountMetaData {
  id: number;
  login: string;
  company: {
    id: number;
    name: string;
  };
  person?: IPerson;
  proxy_id?: number;
  time_zone: string;
  username?: string;
  created: string;
  permission_denied?: boolean;
}

export interface IAccount extends IAccountMetaData, IAccountCurrent {
  country: CountryCodes;
  work_times: IWorkTime;
  restrictions: IAccountRestriction;
  scraping_status: ScrapingStatusValues;
  placeholders: IAccountPlaceholders;
  template_placeholders: IAccountPlaceholders;
  updated: string;
  withdraw_time_delta: string | null;
  system_meta_data: SystemMetaData;
  input_required: number;
}

export interface ICreateAccountResponse extends IAccount {
  card_less_subscription_created: boolean;
}

export interface IAccountNew {
  id: number;
}

export interface IAccounts extends IArrayResponse {
  results: IAccount[];
}

export interface IAccountDetailProps {
  account: IAccount;
}

export interface IAccountDetailItemProps {
  accountId: number;
}

export interface IAccountLogStrategy {
  description: React.ReactElement;
  label: string;
  strategy: AccountLogStrategy;
  key: string[] | string;
  disableInput?: boolean;
  input?: React.FunctionComponent<any>;
}

export interface IAccountLog {
  id: number;
  account: number;
  status: IAccountLogStatuses;
  msg: string;
  user_input: {
    pin?: string;
  };
  strategy: AccountLogStrategy | null;
  answered: boolean;
  created: string;
}

export interface IAccountLogs extends IArrayResponse {
  results: IAccountLog[];
}

export interface IAccountPasswordChangeFormInput {
  password: string;
  password_confirm: string;
}

export interface IAccountPasswordChangeFormValues {
  password: string;
}

export interface ITimeZoneFormInput {
  id: TimeZones | string;
  name: string;
}

export interface ICreateAccountStepValues extends ICreateStepValues {
  accountId: number;
}

export interface ICountryValues {
  id: string;
  name: CountryCodes | string;
}

export interface ICountryFormInput {
  country: ICountryValues | null;
}

export interface IProxyIpsMappedData {
  account: number;
  country: CountryCodes;
}

export interface IProxyIpsResponse extends IProxyIpsMappedData, IAccount {
  created: string;
  updated: string;
}

export interface IInactivePeriod {
  id?: number;
  start_date: string;
  end_date: string;
}

export interface IInactivePeriods extends IArrayResponse {
  results: IInactivePeriod[];
}

export interface IIsOnline {
  is_work_date_time: boolean;
  is_in_work_time_range: boolean;
  is_inactive_day: boolean;
  session_open_at: string; // Last time connected
  next_session_time_after: string; // Next time connection will be possible
  input_required: number; // Account log input required when can't login to LinkedIn account
  fuse_limit_at: string;
}

export interface IAccountStatusBadge {
  tooltip: string;
  color: ChipColors;
}

export interface IAccountStatusChip extends IAccountStatusBadge {
  label: string;
  link: string;
}

export interface IAccountChipItem {
  color: ChipColors;
  label: string;
}

export interface IAccountRestrictionRange {
  start: number;
  end: number;
}

type IAccountRestrictionArrayRange = [number, number];

interface IWarmUpRestriction extends IAccountRestrictionRange {
  enabled: boolean;
  increase_rate_range: IAccountRestrictionRange;
}

export interface IAccountRestriction {
  warm_up: IWarmUpRestriction;
  connection_requests_daily_range: IAccountRestrictionRange;
  follow_up_messages: IAccountRestrictionRange;
  open_connection_requests: number;
}

export interface IAccountRestrictionFormInput {
  warm_up: {
    enabled: boolean;
    increase_rate_range: IAccountRestrictionArrayRange;
    interactions_range: IAccountRestrictionArrayRange;
  };
  connection_requests_daily_range: IAccountRestrictionArrayRange;
  follow_up_messages: IAccountRestrictionArrayRange;
}

export interface IPreviewTemplateInput {
  placeholders?: { [key: string]: string };
  person?: number;
  template: string;
  locale?: CampaignLocale;
}

export interface IPreviewTemplateOutput {
  content: string;
}

export interface ICreateAccountApiKeyFormValues {
  name?: string;
  account: number;
  expiry_date?: string;
  revoked?: boolean;
}

export interface ICreatedAccountApiKey extends ICreateAccountApiKeyFormValues {
  key: string;
  id: string;
}

export interface IUpdateAccountApiKeyFormInput {
  name?: string;
  expiry_date?: string;
  revoked?: boolean;
}

export interface IUpdateAccountApiKeyFormValues {
  name?: string;
  account: number;
  expiry_date?: string;
  revoked?: boolean;
}

export interface IAccountApiKey extends ICreateAccountApiKeyFormValues {
  created: string;
  custom_id: string;
}

export interface IAccountApiKeys extends IArrayResponse {
  results: IAccountApiKey[];
}

export enum AccountLogStrategy {
  "inevitable.strategy.account.AccountCheckYourLinkedInAppStrategy" = "inevitable.strategy.account.AccountCheckYourLinkedInAppStrategy",
  "inevitable.strategy.account.AccountEnterPINStrategy" = "inevitable.strategy.account.AccountEnterPINStrategy",
  "inevitable.strategy.account.AccountEnterSMSPinStrategy" = "inevitable.strategy.account.AccountEnterSMSPinStrategy",
  "inevitable.strategy.account.AccountEnterPasswordStrategy" = "inevitable.strategy.account.AccountEnterPasswordStrategy",
  "inevitable.strategy.account.AccountEnterUsernameStrategy" = "inevitable.strategy.account.AccountEnterUsernameStrategy",
  "inevitable.strategy.account.CorrectPasswordToResumeStrategy" = "inevitable.strategy.account.CorrectPasswordToResumeStrategy",
  "inevitable.strategy.account.CorrectUsernameToResumeStrategy" = "inevitable.strategy.account.CorrectUsernameToResumeStrategy",
  "inevitable.strategy.account.CorrectCredentialsToResumeStrategy" = "inevitable.strategy.account.CorrectCredentialsToResumeStrategy",
  "inevitable.strategy.account.WaitForAccountCheckYourLinkedInAppStrategy" = "inevitable.strategy.account.WaitForAccountCheckYourLinkedInAppStrategy",
  "inevitable.strategy.account.WaitForAccountPINToResumeStrategy" = "inevitable.strategy.account.WaitForAccountPINToResumeStrategy",
  "inevitable.strategy.account.WaitForAccountSMSToResumeStrategy" = "inevitable.strategy.account.WaitForAccountSMSToResumeStrategy",
}

export enum AccountLogStrategyUniqueName {
  "inevitable.strategy.account.AccountCheckYourLinkedInAppStrategy" = "session-app",
  "inevitable.strategy.account.AccountEnterPINStrategy" = "session-pin",
  "inevitable.strategy.account.AccountEnterSMSPinStrategy" = "sms-pin",
  "inevitable.strategy.account.AccountEnterPasswordStrategy" = "session-password",
  "inevitable.strategy.account.AccountEnterUsernameStrategy" = "session-username",
  "inevitable.strategy.account.CorrectPasswordToResumeStrategy" = "input-password",
  "inevitable.strategy.account.CorrectUsernameToResumeStrategy" = "input-username",
  "inevitable.strategy.account.CorrectCredentialsToResumeStrategy" = "input-username-password",
  "inevitable.strategy.account.WaitForAccountCheckYourLinkedInAppStrategy" = "input-app",
  "inevitable.strategy.account.WaitForAccountPINToResumeStrategy" = "input-pin",
  "inevitable.strategy.account.WaitForAccountSMSToResumeStrategy" = "input-sms",
}

// Important to have this as on the server, otherwise the request fails
export enum AccountLogStrategyInputName {
  "inevitable.strategy.account.AccountCheckYourLinkedInAppStrategy" = "pin",
  "inevitable.strategy.account.AccountEnterPINStrategy" = "pin",
  "inevitable.strategy.account.AccountEnterSMSPinStrategy" = "pin",
  "inevitable.strategy.account.AccountEnterPasswordStrategy" = "password",
  "inevitable.strategy.account.AccountEnterUsernameStrategy" = "login",
  "inevitable.strategy.account.CorrectPasswordToResumeStrategy" = "password",
  "inevitable.strategy.account.CorrectUsernameToResumeStrategy" = "login",
  "inevitable.strategy.account.CorrectCredentialsToResumeStrategy" = "login",
  "inevitable.strategy.account.WaitForAccountCheckYourLinkedInAppStrategy" = "pin",
  "inevitable.strategy.account.WaitForAccountPINToResumeStrategy" = "pin",
  "inevitable.strategy.account.WaitForAccountSMSToResumeStrategy" = "pin",
}

export enum IAccountLogStatuses {
  "pr" = "pr",
  "ls" = "ls",
  "il" = "il",
  "lo" = "lo",
  "lf" = "lf",
  "ar" = "ar",
  "lt" = "lt",
  "uf" = "uf",
  "af" = "af",
}

export enum IAccountLogStatusLabels {
  "pr" = "Progress",
  "ls" = "Login successful",
  "il" = "Initial login",
  "lo" = "New login",
  "lf" = "Login failed",
  "ar" = "Action required",
  "lt" = "Login trigger",
  "uf" = "User action was not fulfilled in time",
  "af" = "User action was fulfilled on time",
}

export enum AccountTabs {
  "limits" = "limits",
  "schedule" = "schedule",
  "credentials" = "credentials",
  "placeholders" = "placeholders",
  "integrations" = "integrations",
  "activity" = "activity", // Email link, don't change until you change it on backend too
  "stats" = "stats",
  "privacy" = "privacy",
}

export enum AccountTabsLabels {
  "limits" = "Limits",
  "schedule" = "Schedule",
  "credentials" = "Credentials",
  "placeholders" = "Placeholders",
  "integrations" = "Integrations",
  "activity" = "Activity",
  "blacklist" = "Blacklist",
  "stats" = "Stats",
  "privacy" = "Privacy",
}

export enum TimeZones {
  "Africa/Abidjan" = "Africa/Abidjan",
  "Africa/Accra" = "Africa/Accra",
  "Africa/Addis_Ababa" = "Africa/Addis_Ababa",
  "Africa/Algiers" = "Africa/Algiers",
  "Africa/Asmara" = "Africa/Asmara",
  "Africa/Bamako" = "Africa/Bamako",
  "Africa/Bangui" = "Africa/Bangui",
  "Africa/Banjul" = "Africa/Banjul",
  "Africa/Bissau" = "Africa/Bissau",
  "Africa/Blantyre" = "Africa/Blantyre",
  "Africa/Brazzaville" = "Africa/Brazzaville",
  "Africa/Bujumbura" = "Africa/Bujumbura",
  "Africa/Cairo" = "Africa/Cairo",
  "Africa/Casablanca" = "Africa/Casablanca",
  "Africa/Ceuta" = "Africa/Ceuta",
  "Africa/Conakry" = "Africa/Conakry",
  "Africa/Dakar" = "Africa/Dakar",
  "Africa/Dar_es_Salaam" = "Africa/Dar_es_Salaam",
  "Africa/Djibouti" = "Africa/Djibouti",
  "Africa/Douala" = "Africa/Douala",
  "Africa/El_Aaiun" = "Africa/El_Aaiun",
  "Africa/Freetown" = "Africa/Freetown",
  "Africa/Gaborone" = "Africa/Gaborone",
  "Africa/Harare" = "Africa/Harare",
  "Africa/Johannesburg" = "Africa/Johannesburg",
  "Africa/Juba" = "Africa/Juba",
  "Africa/Kampala" = "Africa/Kampala",
  "Africa/Khartoum" = "Africa/Khartoum",
  "Africa/Kigali" = "Africa/Kigali",
  "Africa/Kinshasa" = "Africa/Kinshasa",
  "Africa/Lagos" = "Africa/Lagos",
  "Africa/Libreville" = "Africa/Libreville",
  "Africa/Lome" = "Africa/Lome",
  "Africa/Luanda" = "Africa/Luanda",
  "Africa/Lubumbashi" = "Africa/Lubumbashi",
  "Africa/Lusaka" = "Africa/Lusaka",
  "Africa/Malabo" = "Africa/Malabo",
  "Africa/Maputo" = "Africa/Maputo",
  "Africa/Maseru" = "Africa/Maseru",
  "Africa/Mbabane" = "Africa/Mbabane",
  "Africa/Mogadishu" = "Africa/Mogadishu",
  "Africa/Monrovia" = "Africa/Monrovia",
  "Africa/Nairobi" = "Africa/Nairobi",
  "Africa/Ndjamena" = "Africa/Ndjamena",
  "Africa/Niamey" = "Africa/Niamey",
  "Africa/Nouakchott" = "Africa/Nouakchott",
  "Africa/Ouagadougou" = "Africa/Ouagadougou",
  "Africa/Porto-Novo" = "Africa/Porto-Novo",
  "Africa/Sao_Tome" = "Africa/Sao_Tome",
  "Africa/Tripoli" = "Africa/Tripoli",
  "Africa/Tunis" = "Africa/Tunis",
  "Africa/Windhoek" = "Africa/Windhoek",
  "America/Adak" = "America/Adak",
  "America/Anchorage" = "America/Anchorage",
  "America/Anguilla" = "America/Anguilla",
  "America/Antigua" = "America/Antigua",
  "America/Araguaina" = "America/Araguaina",
  "America/Argentina/Buenos_Aires" = "America/Argentina/Buenos_Aires",
  "America/Argentina/Catamarca" = "America/Argentina/Catamarca",
  "America/Argentina/Cordoba" = "America/Argentina/Cordoba",
  "America/Argentina/Jujuy" = "America/Argentina/Jujuy",
  "America/Argentina/La_Rioja" = "America/Argentina/La_Rioja",
  "America/Argentina/Mendoza" = "America/Argentina/Mendoza",
  "America/Argentina/Rio_Gallegos" = "America/Argentina/Rio_Gallegos",
  "America/Argentina/Salta" = "America/Argentina/Salta",
  "America/Argentina/San_Juan" = "America/Argentina/San_Juan",
  "America/Argentina/San_Luis" = "America/Argentina/San_Luis",
  "America/Argentina/Tucuman" = "America/Argentina/Tucuman",
  "America/Argentina/Ushuaia" = "America/Argentina/Ushuaia",
  "America/Aruba" = "America/Aruba",
  "America/Asuncion" = "America/Asuncion",
  "America/Atikokan" = "America/Atikokan",
  "America/Bahia" = "America/Bahia",
  "America/Bahia_Banderas" = "America/Bahia_Banderas",
  "America/Barbados" = "America/Barbados",
  "America/Belem" = "America/Belem",
  "America/Belize" = "America/Belize",
  "America/Blanc-Sablon" = "America/Blanc-Sablon",
  "America/Boa_Vista" = "America/Boa_Vista",
  "America/Bogota" = "America/Bogota",
  "America/Boise" = "America/Boise",
  "America/Cambridge_Bay" = "America/Cambridge_Bay",
  "America/Campo_Grande" = "America/Campo_Grande",
  "America/Cancun" = "America/Cancun",
  "America/Caracas" = "America/Caracas",
  "America/Cayenne" = "America/Cayenne",
  "America/Cayman" = "America/Cayman",
  "America/Chicago" = "America/Chicago",
  "America/Chihuahua" = "America/Chihuahua",
  "America/Costa_Rica" = "America/Costa_Rica",
  "America/Creston" = "America/Creston",
  "America/Cuiaba" = "America/Cuiaba",
  "America/Curacao" = "America/Curacao",
  "America/Danmarkshavn" = "America/Danmarkshavn",
  "America/Dawson" = "America/Dawson",
  "America/Dawson_Creek" = "America/Dawson_Creek",
  "America/Denver" = "America/Denver",
  "America/Detroit" = "America/Detroit",
  "America/Dominica" = "America/Dominica",
  "America/Edmonton" = "America/Edmonton",
  "America/Eirunepe" = "America/Eirunepe",
  "America/El_Salvador" = "America/El_Salvador",
  "America/Fort_Nelson" = "America/Fort_Nelson",
  "America/Fortaleza" = "America/Fortaleza",
  "America/Glace_Bay" = "America/Glace_Bay",
  "America/Godthab" = "America/Godthab",
  "America/Goose_Bay" = "America/Goose_Bay",
  "America/Grand_Turk" = "America/Grand_Turk",
  "America/Grenada" = "America/Grenada",
  "America/Guadeloupe" = "America/Guadeloupe",
  "America/Guatemala" = "America/Guatemala",
  "America/Guayaquil" = "America/Guayaquil",
  "America/Guyana" = "America/Guyana",
  "America/Halifax" = "America/Halifax",
  "America/Havana" = "America/Havana",
  "America/Hermosillo" = "America/Hermosillo",
  "America/Indiana/Indianapolis" = "America/Indiana/Indianapolis",
  "America/Indiana/Knox" = "America/Indiana/Knox",
  "America/Indiana/Marengo" = "America/Indiana/Marengo",
  "America/Indiana/Petersburg" = "America/Indiana/Petersburg",
  "America/Indiana/Tell_City" = "America/Indiana/Tell_City",
  "America/Indiana/Vevay" = "America/Indiana/Vevay",
  "America/Indiana/Vincennes" = "America/Indiana/Vincennes",
  "America/Indiana/Winamac" = "America/Indiana/Winamac",
  "America/Inuvik" = "America/Inuvik",
  "America/Iqaluit" = "America/Iqaluit",
  "America/Jamaica" = "America/Jamaica",
  "America/Juneau" = "America/Juneau",
  "America/Kentucky/Louisville" = "America/Kentucky/Louisville",
  "America/Kentucky/Monticello" = "America/Kentucky/Monticello",
  "America/Kralendijk" = "America/Kralendijk",
  "America/La_Paz" = "America/La_Paz",
  "America/Lima" = "America/Lima",
  "America/Los_Angeles" = "America/Los_Angeles",
  "America/Lower_Princes" = "America/Lower_Princes",
  "America/Maceio" = "America/Maceio",
  "America/Managua" = "America/Managua",
  "America/Manaus" = "America/Manaus",
  "America/Marigot" = "America/Marigot",
  "America/Martinique" = "America/Martinique",
  "America/Matamoros" = "America/Matamoros",
  "America/Mazatlan" = "America/Mazatlan",
  "America/Mendoza" = "America/Mendoza",
  "America/Menominee" = "America/Menominee",
  "America/Merida" = "America/Merida",
  "America/Metlakatla" = "America/Metlakatla",
  "America/Mexico_City" = "America/Mexico_City",
  "America/Miquelon" = "America/Miquelon",
  "America/Moncton" = "America/Moncton",
  "America/Monterrey" = "America/Monterrey",
  "America/Montevideo" = "America/Montevideo",
  "America/Montreal" = "America/Montreal",
  "America/Montserrat" = "America/Montserrat",
  "America/Nassau" = "America/Nassau",
  "America/New_York" = "America/New_York",
  "America/Nipigon" = "America/Nipigon",
  "America/Nome" = "America/Nome",
  "America/Noronha" = "America/Noronha",
  "America/North_Dakota/Beulah" = "America/North_Dakota/Beulah",
  "America/North_Dakota/Center" = "America/North_Dakota/Center",
  "America/North_Dakota/New_Salem" = "America/North_Dakota/New_Salem",
  "America/Nuuk" = "America/Nuuk",
  "America/Ojinaga" = "America/Ojinaga",
  "America/Panama" = "America/Panama",
  "America/Pangnirtung" = "America/Pangnirtung",
  "America/Paramaribo" = "America/Paramaribo",
  "America/Phoenix" = "America/Phoenix",
  "America/Port-au-Prince" = "America/Port-au-Prince",
  "America/Port_of_Spain" = "America/Port_of_Spain",
  "America/Porto_Velho" = "America/Porto_Velho",
  "America/Puerto_Rico" = "America/Puerto_Rico",
  "America/Punta_Arenas" = "America/Punta_Arenas",
  "America/Rainy_River" = "America/Rainy_River",
  "America/Rankin_Inlet" = "America/Rankin_Inlet",
  "America/Recife" = "America/Recife",
  "America/Regina" = "America/Regina",
  "America/Resolute" = "America/Resolute",
  "America/Rio_Branco" = "America/Rio_Branco",
  "America/Santarem" = "America/Santarem",
  "America/Santiago" = "America/Santiago",
  "America/Santo_Domingo" = "America/Santo_Domingo",
  "America/Sao_Paulo" = "America/Sao_Paulo",
  "America/Scoresbysund" = "America/Scoresbysund",
  "America/Sitka" = "America/Sitka",
  "America/St_Barthelemy" = "America/St_Barthelemy",
  "America/St_Johns" = "America/St_Johns",
  "America/St_Kitts" = "America/St_Kitts",
  "America/St_Lucia" = "America/St_Lucia",
  "America/St_Thomas" = "America/St_Thomas",
  "America/St_Vincent" = "America/St_Vincent",
  "America/Swift_Current" = "America/Swift_Current",
  "America/Tegucigalpa" = "America/Tegucigalpa",
  "America/Thule" = "America/Thule",
  "America/Thunder_Bay" = "America/Thunder_Bay",
  "America/Tijuana" = "America/Tijuana",
  "America/Toronto" = "America/Toronto",
  "America/Tortola" = "America/Tortola",
  "America/Vancouver" = "America/Vancouver",
  "America/Whitehorse" = "America/Whitehorse",
  "America/Winnipeg" = "America/Winnipeg",
  "America/Yakutat" = "America/Yakutat",
  "America/Yellowknife" = "America/Yellowknife",
  "Antarctica/Casey" = "Antarctica/Casey",
  "Antarctica/Davis" = "Antarctica/Davis",
  "Antarctica/DumontDUrville" = "Antarctica/DumontDUrville",
  "Antarctica/Macquarie" = "Antarctica/Macquarie",
  "Antarctica/Mawson" = "Antarctica/Mawson",
  "Antarctica/McMurdo" = "Antarctica/McMurdo",
  "Antarctica/Palmer" = "Antarctica/Palmer",
  "Antarctica/Rothera" = "Antarctica/Rothera",
  "Antarctica/Syowa" = "Antarctica/Syowa",
  "Antarctica/Troll" = "Antarctica/Troll",
  "Antarctica/Vostok" = "Antarctica/Vostok",
  "Arctic/Longyearbyen" = "Arctic/Longyearbyen",
  "Asia/Aden" = "Asia/Aden",
  "Asia/Almaty" = "Asia/Almaty",
  "Asia/Amman" = "Asia/Amman",
  "Asia/Anadyr" = "Asia/Anadyr",
  "Asia/Aqtau" = "Asia/Aqtau",
  "Asia/Aqtobe" = "Asia/Aqtobe",
  "Asia/Ashgabat" = "Asia/Ashgabat",
  "Asia/Atyrau" = "Asia/Atyrau",
  "Asia/Baghdad" = "Asia/Baghdad",
  "Asia/Bahrain" = "Asia/Bahrain",
  "Asia/Baku" = "Asia/Baku",
  "Asia/Bangkok" = "Asia/Bangkok",
  "Asia/Barnaul" = "Asia/Barnaul",
  "Asia/Beirut" = "Asia/Beirut",
  "Asia/Bishkek" = "Asia/Bishkek",
  "Asia/Brunei" = "Asia/Brunei",
  "Asia/Chita" = "Asia/Chita",
  "Asia/Choibalsan" = "Asia/Choibalsan",
  "Asia/Colombo" = "Asia/Colombo",
  "Asia/Damascus" = "Asia/Damascus",
  "Asia/Dhaka" = "Asia/Dhaka",
  "Asia/Dili" = "Asia/Dili",
  "Asia/Dubai" = "Asia/Dubai",
  "Asia/Dushanbe" = "Asia/Dushanbe",
  "Asia/Famagusta" = "Asia/Famagusta",
  "Asia/Gaza" = "Asia/Gaza",
  "Asia/Hebron" = "Asia/Hebron",
  "Asia/Ho_Chi_Minh" = "Asia/Ho_Chi_Minh",
  "Asia/Hong_Kong" = "Asia/Hong_Kong",
  "Asia/Hovd" = "Asia/Hovd",
  "Asia/Irkutsk" = "Asia/Irkutsk",
  "Asia/Jakarta" = "Asia/Jakarta",
  "Asia/Jayapura" = "Asia/Jayapura",
  "Asia/Jerusalem" = "Asia/Jerusalem",
  "Asia/Kabul" = "Asia/Kabul",
  "Asia/Kamchatka" = "Asia/Kamchatka",
  "Asia/Karachi" = "Asia/Karachi",
  "Asia/Kathmandu" = "Asia/Kathmandu",
  "Asia/Khandyga" = "Asia/Khandyga",
  "Asia/Kolkata" = "Asia/Kolkata",
  "Asia/Calcutta" = "Asia/Kolkata",
  "Asia/Krasnoyarsk" = "Asia/Krasnoyarsk",
  "Asia/Kuala_Lumpur" = "Asia/Kuala_Lumpur",
  "Asia/Kuching" = "Asia/Kuching",
  "Asia/Kuwait" = "Asia/Kuwait",
  "Asia/Macau" = "Asia/Macau",
  "Asia/Magadan" = "Asia/Magadan",
  "Asia/Makassar" = "Asia/Makassar",
  "Asia/Manila" = "Asia/Manila",
  "Asia/Muscat" = "Asia/Muscat",
  "Asia/Nicosia" = "Asia/Nicosia",
  "Asia/Novokuznetsk" = "Asia/Novokuznetsk",
  "Asia/Novosibirsk" = "Asia/Novosibirsk",
  "Asia/Omsk" = "Asia/Omsk",
  "Asia/Oral" = "Asia/Oral",
  "Asia/Phnom_Penh" = "Asia/Phnom_Penh",
  "Asia/Pontianak" = "Asia/Pontianak",
  "Asia/Pyongyang" = "Asia/Pyongyang",
  "Asia/Qatar" = "Asia/Qatar",
  "Asia/Qostanay" = "Asia/Qostanay",
  "Asia/Qyzylorda" = "Asia/Qyzylorda",
  "Asia/Riyadh" = "Asia/Riyadh",
  "Asia/Sakhalin" = "Asia/Sakhalin",
  "Asia/Samarkand" = "Asia/Samarkand",
  "Asia/Seoul" = "Asia/Seoul",
  "Asia/Shanghai" = "Asia/Shanghai",
  "Asia/Singapore" = "Asia/Singapore",
  "Asia/Srednekolymsk" = "Asia/Srednekolymsk",
  "Asia/Taipei" = "Asia/Taipei",
  "Asia/Tashkent" = "Asia/Tashkent",
  "Asia/Tbilisi" = "Asia/Tbilisi",
  "Asia/Tehran" = "Asia/Tehran",
  "Asia/Thimphu" = "Asia/Thimphu",
  "Asia/Tokyo" = "Asia/Tokyo",
  "Asia/Tomsk" = "Asia/Tomsk",
  "Asia/Ulaanbaatar" = "Asia/Ulaanbaatar",
  "Asia/Urumqi" = "Asia/Urumqi",
  "Asia/Ust-Nera" = "Asia/Ust-Nera",
  "Asia/Vientiane" = "Asia/Vientiane",
  "Asia/Vladivostok" = "Asia/Vladivostok",
  "Asia/Yakutsk" = "Asia/Yakutsk",
  "Asia/Yangon" = "Asia/Yangon",
  "Asia/Yekaterinburg" = "Asia/Yekaterinburg",
  "Asia/Yerevan" = "Asia/Yerevan",
  "Atlantic/Azores" = "Atlantic/Azores",
  "Atlantic/Bermuda" = "Atlantic/Bermuda",
  "Atlantic/Canary" = "Atlantic/Canary",
  "Atlantic/Cape_Verde" = "Atlantic/Cape_Verde",
  "Atlantic/Faroe" = "Atlantic/Faroe",
  "Atlantic/Madeira" = "Atlantic/Madeira",
  "Atlantic/Reykjavik" = "Atlantic/Reykjavik",
  "Atlantic/South_Georgia" = "Atlantic/South_Georgia",
  "Atlantic/St_Helena" = "Atlantic/St_Helena",
  "Atlantic/Stanley" = "Atlantic/Stanley",
  "Australia/Adelaide" = "Australia/Adelaide",
  "Australia/Brisbane" = "Australia/Brisbane",
  "Australia/Broken_Hill" = "Australia/Broken_Hill",
  "Australia/Currie" = "Australia/Currie",
  "Australia/Darwin" = "Australia/Darwin",
  "Australia/Eucla" = "Australia/Eucla",
  "Australia/Hobart" = "Australia/Hobart",
  "Australia/Lindeman" = "Australia/Lindeman",
  "Australia/Lord_Howe" = "Australia/Lord_Howe",
  "Australia/Melbourne" = "Australia/Melbourne",
  "Australia/Perth" = "Australia/Perth",
  "Australia/Sydney" = "Australia/Sydney",
  "Canada/Atlantic" = "Canada/Atlantic",
  "Canada/Central" = "Canada/Central",
  "Canada/Eastern" = "Canada/Eastern",
  "Canada/Mountain" = "Canada/Mountain",
  "Canada/Newfoundland" = "Canada/Newfoundland",
  "Canada/Pacific" = "Canada/Pacific",
  "Europe/Amsterdam" = "Europe/Amsterdam",
  "Europe/Andorra" = "Europe/Andorra",
  "Europe/Astrakhan" = "Europe/Astrakhan",
  "Europe/Athens" = "Europe/Athens",
  "Europe/Belgrade" = "Europe/Belgrade",
  "Europe/Berlin" = "Europe/Berlin",
  "Europe/Bratislava" = "Europe/Bratislava",
  "Europe/Brussels" = "Europe/Brussels",
  "Europe/Bucharest" = "Europe/Bucharest",
  "Europe/Budapest" = "Europe/Budapest",
  "Europe/Busingen" = "Europe/Busingen",
  "Europe/Chisinau" = "Europe/Chisinau",
  "Europe/Copenhagen" = "Europe/Copenhagen",
  "Europe/Dublin" = "Europe/Dublin",
  "Europe/Gibraltar" = "Europe/Gibraltar",
  "Europe/Guernsey" = "Europe/Guernsey",
  "Europe/Helsinki" = "Europe/Helsinki",
  "Europe/Isle_of_Man" = "Europe/Isle_of_Man",
  "Europe/Istanbul" = "Europe/Istanbul",
  "Europe/Jersey" = "Europe/Jersey",
  "Europe/Kaliningrad" = "Europe/Kaliningrad",
  "Europe/Kiev" = "Europe/Kiev",
  "Europe/Kirov" = "Europe/Kirov",
  "Europe/Lisbon" = "Europe/Lisbon",
  "Europe/Ljubljana" = "Europe/Ljubljana",
  "Europe/London" = "Europe/London",
  "Europe/Luxembourg" = "Europe/Luxembourg",
  "Europe/Madrid" = "Europe/Madrid",
  "Europe/Malta" = "Europe/Malta",
  "Europe/Mariehamn" = "Europe/Mariehamn",
  "Europe/Minsk" = "Europe/Minsk",
  "Europe/Monaco" = "Europe/Monaco",
  "Europe/Moscow" = "Europe/Moscow",
  "Europe/Oslo" = "Europe/Oslo",
  "Europe/Paris" = "Europe/Paris",
  "Europe/Podgorica" = "Europe/Podgorica",
  "Europe/Prague" = "Europe/Prague",
  "Europe/Riga" = "Europe/Riga",
  "Europe/Rome" = "Europe/Rome",
  "Europe/Samara" = "Europe/Samara",
  "Europe/San_Marino" = "Europe/San_Marino",
  "Europe/Sarajevo" = "Europe/Sarajevo",
  "Europe/Saratov" = "Europe/Saratov",
  "Europe/Simferopol" = "Europe/Simferopol",
  "Europe/Skopje" = "Europe/Skopje",
  "Europe/Sofia" = "Europe/Sofia",
  "Europe/Stockholm" = "Europe/Stockholm",
  "Europe/Tallinn" = "Europe/Tallinn",
  "Europe/Tirane" = "Europe/Tirane",
  "Europe/Ulyanovsk" = "Europe/Ulyanovsk",
  "Europe/Uzhgorod" = "Europe/Uzhgorod",
  "Europe/Vaduz" = "Europe/Vaduz",
  "Europe/Vatican" = "Europe/Vatican",
  "Europe/Vienna" = "Europe/Vienna",
  "Europe/Vilnius" = "Europe/Vilnius",
  "Europe/Volgograd" = "Europe/Volgograd",
  "Europe/Warsaw" = "Europe/Warsaw",
  "Europe/Zagreb" = "Europe/Zagreb",
  "Europe/Zaporozhye" = "Europe/Zaporozhye",
  "Europe/Zurich" = "Europe/Zurich",
  "GMT" = "GMT",
  "Indian/Antananarivo" = "Indian/Antananarivo",
  "Indian/Chagos" = "Indian/Chagos",
  "Indian/Christmas" = "Indian/Christmas",
  "Indian/Cocos" = "Indian/Cocos",
  "Indian/Comoro" = "Indian/Comoro",
  "Indian/Kerguelen" = "Indian/Kerguelen",
  "Indian/Mahe" = "Indian/Mahe",
  "Indian/Maldives" = "Indian/Maldives",
  "Indian/Mauritius" = "Indian/Mauritius",
  "Indian/Mayotte" = "Indian/Mayotte",
  "Indian/Reunion" = "Indian/Reunion",
  "Pacific/Apia" = "Pacific/Apia",
  "Pacific/Auckland" = "Pacific/Auckland",
  "Pacific/Bougainville" = "Pacific/Bougainville",
  "Pacific/Chatham" = "Pacific/Chatham",
  "Pacific/Chuuk" = "Pacific/Chuuk",
  "Pacific/Easter" = "Pacific/Easter",
  "Pacific/Efate" = "Pacific/Efate",
  "Pacific/Enderbury" = "Pacific/Enderbury",
  "Pacific/Fakaofo" = "Pacific/Fakaofo",
  "Pacific/Fiji" = "Pacific/Fiji",
  "Pacific/Funafuti" = "Pacific/Funafuti",
  "Pacific/Galapagos" = "Pacific/Galapagos",
  "Pacific/Gambier" = "Pacific/Gambier",
  "Pacific/Guadalcanal" = "Pacific/Guadalcanal",
  "Pacific/Guam" = "Pacific/Guam",
  "Pacific/Honolulu" = "Pacific/Honolulu",
  "Pacific/Kiritimati" = "Pacific/Kiritimati",
  "Pacific/Kosrae" = "Pacific/Kosrae",
  "Pacific/Kwajalein" = "Pacific/Kwajalein",
  "Pacific/Majuro" = "Pacific/Majuro",
  "Pacific/Marquesas" = "Pacific/Marquesas",
  "Pacific/Midway" = "Pacific/Midway",
  "Pacific/Nauru" = "Pacific/Nauru",
  "Pacific/Niue" = "Pacific/Niue",
  "Pacific/Norfolk" = "Pacific/Norfolk",
  "Pacific/Noumea" = "Pacific/Noumea",
  "Pacific/Pago_Pago" = "Pacific/Pago_Pago",
  "Pacific/Palau" = "Pacific/Palau",
  "Pacific/Pitcairn" = "Pacific/Pitcairn",
  "Pacific/Pohnpei" = "Pacific/Pohnpei",
  "Pacific/Port_Moresby" = "Pacific/Port_Moresby",
  "Pacific/Rarotonga" = "Pacific/Rarotonga",
  "Pacific/Saipan" = "Pacific/Saipan",
  "Pacific/Tahiti" = "Pacific/Tahiti",
  "Pacific/Tarawa" = "Pacific/Tarawa",
  "Pacific/Tongatapu" = "Pacific/Tongatapu",
  "Pacific/Wake" = "Pacific/Wake",
  "Pacific/Wallis" = "Pacific/Wallis",
  "US/Alaska" = "US/Alaska",
  "US/Arizona" = "US/Arizona",
  "US/Central" = "US/Central",
  "US/Eastern" = "US/Eastern",
  "US/Hawaii" = "US/Hawaii",
  "US/Mountain" = "US/Mountain",
  "US/Pacific" = "US/Pacific",
  "UTC" = "UTC",
}

export interface ILocationResponse {
  data: {
    status: string;
    country: string;
    countryCode: string;
  };
}
