import React, { createContext, useReducer } from "react";
import { IAccountCurrent } from "../models";
import { AccountActions, accountReducer } from "../reducers";
import { getCurrentAccount } from "../utils";

interface IProps {
  children: React.ReactElement;
}

export interface IAccountContext {
  account: IAccountCurrent;
  dispatch: React.Dispatch<AccountActions>;
}

const initialState = {
  account: getCurrentAccount(),
  dispatch: () => null,
};

export const AccountContext = createContext<IAccountContext>(initialState);

const AccountContextProvider = (props: IProps): React.ReactElement => {
  const [account, dispatch] = useReducer(accountReducer, initialState.account);
  const { children } = props;

  return (
    <AccountContext.Provider value={{ account, dispatch }}>
      {children}
    </AccountContext.Provider>
  );
};

export default AccountContextProvider;
