import { Tooltip } from "@mui/material";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import { Theme } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import ConfigConstant from "core/constants/ConfigConstant";
import { GlobalContext } from "core/context";
import { DiffInterval } from "core/models";
import { GlobalActionType } from "core/reducers";
import RouterConstants from "core/routes/constants";
import {
  dayjs,
  differenceByInterval,
  formatDateTimeToFull,
  formatDateToFull,
  parseStripeDate,
} from "core/utils/dateHandler";
import { errorHandler, IErrorResponse } from "core/utils/errorHandler";
import { snackbarHandler } from "core/utils/snackbarHandler";
import AccountStats from "modules/Account/components/AccountStats";
import { AccountQuery } from "modules/Account/models";
import AccountService from "modules/Account/services";
import { getAccountName } from "modules/Account/utils";
import CancelationDialog from "modules/Payment/components/CancelationDialog";
import DiscountPrice from "modules/Payment/components/DiscountPrice";
import TransferSubscriptionDialog from "modules/Payment/components/TransferSubscriptionDialog";
import { SubscriptionStatus } from "modules/Payment/models";
import PaymentService from "modules/Payment/services";
import PaymentUtils from "modules/Payment/utils";
import React, { useMemo } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import Button from "ui-kit/atoms/Button";
import { Chip } from "ui-kit/atoms/Chip";
import Loader from "ui-kit/components/Loader";
import PageHeader from "ui-kit/components/PageHeader";

const useStyles = makeStyles((theme: Theme) => ({
  name: {
    color: theme.palette.text.primary,
  },
  value: {
    color: theme.app.palette.action.color,
    textAlign: "right",
  },
}));

type ParamTypes = {
  subscriptionId: string;
};

const BillingDetail = (): React.ReactElement | null => {
  const classes = useStyles();
  const history = useHistory();
  const queryClient = useQueryClient();
  const { subscriptionId } = useParams<ParamTypes>();
  const [openTransfer, setTransfer] = React.useState(false);
  const setTransferToggle = () => setTransfer(!openTransfer);

  const { dispatch } = React.useContext(GlobalContext);

  const setCancelToggle = () => {
    dispatch({
      type: GlobalActionType.SET_GLOBAL,
      payload: { cancelDialog: true },
    });
  };

  const { data, isLoading } = useQuery(
    ["subscription", subscriptionId],
    async () => {
      try {
        const response = await PaymentService.fetchSubscription(subscriptionId);
        return response.data;
      } catch (err) {
        throw new Error(String(err));
      }
    }
  );

  const { data: account } = useQuery(
    [AccountQuery.account, { accountId: data?.seat.id }],
    async () => {
      try {
        const response = await AccountService.fetchAccount(
          Number(data?.seat.id)
        );
        return response.data;
      } catch (err) {
        throw new Error(String(err));
      }
    },
    {
      keepPreviousData: true,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      enabled: !!data?.seat.id,
    }
  );

  const mutateOnClick = useMutation(
    () => {
      const returnUrl = `${
        ConfigConstant.BASE_URL
      }${RouterConstants.BILLING.detail(subscriptionId)}`;
      const customerId = data?.customer.id;
      if (!customerId) {
        throw new Error("No valid customer exists for this subscription.");
      }
      return PaymentService.createCustomerPortalSession(customerId, {
        return_url: returnUrl,
      });
    },
    {
      onSuccess: async (response) => {
        window.location.href = response.data.url;
      },
      onError: (error: IErrorResponse) => {
        errorHandler(error.response);
      },
    }
  );

  const mutateRenewSubscription = useMutation(
    () => PaymentService.renewSubscription(subscriptionId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["subscription", subscriptionId]);
        history.push(RouterConstants.BILLING.detail(subscriptionId));

        snackbarHandler.success("Your subscription renewed successfully.");
      },
      onError: (error: IErrorResponse) => {
        errorHandler(error.response);
      },
    }
  );

  const accountName = useMemo(() => {
    return getAccountName(account);
  }, [account]);

  const btnTransferAccount = useMemo(
    () => (
      <Tooltip title="Transfer plan to another LinkedIn account">
        <div>
          <Button
            variant="contained"
            color="primary"
            disabled={!data?.current_user_is_owner}
            type="button"
            onClick={setTransferToggle}
          >
            Transfer subscription
          </Button>
        </div>
      </Tooltip>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data?.current_user_is_owner]
  );

  const transferBox = useMemo(
    () =>
      !!data?.current_user_is_owner && (
        <>
          <Box sx={{ py: 4, pb: 8 }}>
            <Box sx={{ pb: 4 }}>
              <Typography variant="h6" gutterBottom>
                Transfer subscription
              </Typography>
              <Typography variant="body2" sx={{ mb: 2 }}>
                Here you can transfer the subscription for {accountName} to
                another LinkedIn account.
              </Typography>
            </Box>
            {btnTransferAccount}
          </Box>
          <Divider />
        </>
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data?.current_user_is_owner]
  );

  if (!data) {
    return null;
  }

  const noPermission = !data.current_user_is_owner && (
    <Typography variant="body2" color="error" paragraph>
      No permission. Only subscription owner can manage the subscription.
    </Typography>
  );

  const accountDetails = (
    <>
      <Box sx={{ pt: 2, pb: 8 }}>
        <Box sx={{ pb: 2 }}>
          <Typography variant="h6">Your plan</Typography>
          {data?.canceled_at && (
            <Box sx={{ py: 4 }}>
              <Typography variant="body1" gutterBottom color="error">
                You canceled your subscription on{" "}
                {formatDateTimeToFull(data?.canceled_at, true)}.
              </Typography>
              <Typography variant="body2" gutterBottom>
                It stays active until {formatDateToFull(data?.cancel_at, true)}.
                If you want to keep using this account, renew the subscription
                with no charge.
              </Typography>
            </Box>
          )}
          {data?.canceled_at && (
            <Box sx={{ display: "flex", gap: 3, marginBottom: 2 }}>
              <Button
                color="success"
                variant="contained"
                type="button"
                onClick={() => mutateRenewSubscription.mutate()}
              >
                Renew subscription
              </Button>
              {btnTransferAccount}
            </Box>
          )}
          <Table sx={{ maxWidth: 460, my: 4 }}>
            <TableBody>
              <TableRow>
                <TableCell className={classes.name}>Status</TableCell>
                <TableCell className={classes.value}>
                  {data?.canceled_at ? (
                    <Chip
                      variant="outlined"
                      sx={{ textTransform: "capitalize" }}
                      size="small"
                      color="warning"
                      label={`Ends ${formatDateToFull(
                        data?.canceled_at,
                        true
                      )}`}
                    />
                  ) : (
                    <Chip
                      variant="outlined"
                      sx={{ textTransform: "capitalize" }}
                      size="small"
                      color={PaymentUtils.getChipColor(data?.status)}
                      label={data?.status}
                    />
                  )}
                </TableCell>
              </TableRow>
              {data?.status === SubscriptionStatus.trialing && (
                <TableRow>
                  <TableCell className={classes.name}>
                    Free trial ends
                  </TableCell>
                  <TableCell className={classes.value}>
                    {data?.data?.plan
                      ? formatDateTimeToFull(
                          parseStripeDate(data?.data.trial_end),
                          true
                        )
                      : "-"}
                    {" - "}
                    {differenceByInterval(
                      parseStripeDate(data?.data?.trial_end),
                      dayjs(),
                      DiffInterval.day
                    )}
                  </TableCell>
                </TableRow>
              )}

              <TableRow>
                <TableCell className={classes.name}>
                  Next billing date
                </TableCell>
                <TableCell className={classes.value}>
                  {data?.data?.plan && !data.cancel_at
                    ? formatDateToFull(
                        parseStripeDate(data?.data.current_period_end),
                        true
                      )
                    : "-"}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell className={classes.name}>Start date</TableCell>
                <TableCell className={classes.value}>
                  {data?.data?.plan
                    ? formatDateToFull(parseStripeDate(data?.data.start_date))
                    : "-"}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell className={classes.name}>
                  Price{" "}
                  {data?.status === SubscriptionStatus.trialing &&
                    "(after free trial)"}
                </TableCell>
                <TableCell className={classes.value}>
                  {data?.data?.plan
                    ? PaymentUtils.formatStripePriceCurrencyInterval(
                        data?.data?.plan
                      )
                    : "-"}
                </TableCell>
              </TableRow>

              <DiscountPrice
                plan={data?.data?.plan}
                coupon={
                  data.customer.discount?.coupon || data?.data?.discount?.coupon
                }
              />
            </TableBody>
          </Table>
        </Box>
        {noPermission}
        {!data?.canceled_at && (
          <Button
            color="primary"
            variant="contained"
            disabled={!data.current_user_is_owner}
            type="button"
            onClick={() => mutateOnClick.mutate()}
          >
            Edit subscription
          </Button>
        )}
      </Box>
      <Divider />
    </>
  );

  const accountInvoices = data.current_user_is_owner && (
    <>
      <Box sx={{ py: 4, pb: 8 }}>
        <Box sx={{ pb: 4 }}>
          <Typography variant="h6" gutterBottom>
            Invoice history
          </Typography>
          <Typography variant="body2" sx={{ mb: 2 }}>
            Here you can view and download all your invoices.
          </Typography>
        </Box>
        <Button
          color="primary"
          variant="contained"
          type="button"
          onClick={() => mutateOnClick.mutate()}
        >
          View invoice history
        </Button>
      </Box>
      <Divider />
    </>
  );

  const cancelAccount = (
    <>
      <Box sx={{ py: 4, pb: 8 }}>
        <Box sx={{ pb: 4 }}>
          <Typography variant="h6" gutterBottom>
            End subscription
          </Typography>
          <Typography variant="body2" sx={{ mb: 2 }}>
            This action stops all the account's activity for {accountName} by
            the end of the billing period.
          </Typography>
        </Box>
        {noPermission}
        <Box sx={{ display: "flex", gap: 3 }}>
          <Button
            color="error"
            disabled={!data.current_user_is_owner}
            variant="outlined"
            type="button"
            onClick={setCancelToggle}
            // onClick={() =>
            //   history.push(RouterConstants.BILLING.cancel(subscriptionId))
            // }
          >
            Cancel subscription
          </Button>
          {btnTransferAccount}
        </Box>
      </Box>
    </>
  );

  return (
    <>
      <PageHeader
        backlinkProps={{
          text: "Billing",
          link: RouterConstants.BILLING.ALL,
        }}
        actionProps={
          data && data.current_user_is_owner
            ? {
                text: "Edit billing details",
                onClick: () => mutateOnClick.mutate(),
                hiddenStartIcon: true,
              }
            : undefined
        }
        title={!isLoading ? `Billing for ${accountName}` : undefined}
      />
      {isLoading && <Loader />}
      {accountDetails}
      {accountInvoices}
      {transferBox}
      {account?.id && <AccountStats accountId={account.id} />}
      {!data?.canceled_at && cancelAccount}

      {/* Dialogs */}
      <>
        <TransferSubscriptionDialog
          open={openTransfer}
          setToggle={setTransferToggle}
        />
        <CancelationDialog />
      </>
    </>
  );
};

export default BillingDetail;
