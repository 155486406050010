/**
 * Methods for sending toasts to snackbar outside of components (though can be
 * used inside components as well ¯\_(ツ)_/¯)
 *
 * As notistack doesn't have native solution for sending toasts outside of components -
 * here is the solution proposed by community
 * https://github.com/iamhosseindhv/notistack/issues/30#issuecomment-542863653
 *
 * Temporary using notistack-v5 as notistack doesn't support mui-v5 yet
 * https://github.com/iamhosseindhv/notistack/issues/295
 */
import {
  OptionsObject,
  useSnackbar,
  VariantType,
  WithSnackbarProps,
} from "notistack";
import React from "react";

interface IProps {
  setUseSnackbarRef: (showSnackbar: WithSnackbarProps) => void;
}

const InnerSnackbarUtilsConfigurator: React.FC<IProps> = (props: IProps) => {
  const { setUseSnackbarRef } = props;
  setUseSnackbarRef(useSnackbar());
  return null;
};

let useSnackbarRef: WithSnackbarProps;
const setUseSnackbarRef = (useSnackbarRefProp: WithSnackbarProps) => {
  useSnackbarRef = useSnackbarRefProp;
};

export const SnackbarUtilsConfigurator = (): React.ReactElement => {
  return (
    <InnerSnackbarUtilsConfigurator setUseSnackbarRef={setUseSnackbarRef} />
  );
};

export const snackbarHandler = {
  success(msg: string): void {
    this.toast(msg, "success");
  },
  warning(msg: string): void {
    this.toast(msg, "warning");
  },
  info(msg: string): void {
    this.toast(msg, "info");
  },
  error(msg: string): void {
    this.toast(msg, "error");
  },
  toast(msg: string, variant: VariantType = "default"): void {
    const key = useSnackbarRef.enqueueSnackbar(msg, {
      preventDuplicate: true,
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "center",
      },
      autoHideDuration: 10000000,
      variant,
      onClick: () => {
        useSnackbarRef.closeSnackbar(key);
      },
    } as OptionsObject);
  },
};
