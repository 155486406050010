import Close from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import ConfigConstant from "core/constants/ConfigConstant";
import { isBefore } from "core/utils/dateHandler";
import { IErrorResponse, errorHandler } from "core/utils/errorHandler";
import get from "lodash/get";
import { AccountContext } from "modules/Account/context";
import { UserContext } from "modules/User/context";
import { UserActionType } from "modules/User/reducers";
import UserService from "modules/User/services";
import React, { useMemo } from "react";
import { useMutation } from "react-query";

const UPDATE = "2024-05-20T10:40:44.881076Z";

const NewNameUpdate = (): React.ReactElement | null => {
  const { user, dispatch } = React.useContext(UserContext);
  const { account } = React.useContext(AccountContext);

  const accountCreated = useMemo(
    () => get(account, "person.created"),
    [account]
  );

  const isRelevant = useMemo(
    () => isBefore(accountCreated, UPDATE),
    [accountCreated]
  );

  const open = useMemo(() => {
    return (
      isRelevant &&
      !!account &&
      !get(user, "frontend_state.hide_update.domain") &&
      ConfigConstant.APP_ID === 2
    );
  }, [account, isRelevant, user]);

  const mutateOnClose = useMutation(
    () =>
      UserService.updateProfile(user.id, {
        frontend_state: { hide_update: { domain: true } },
      }),
    {
      onSuccess: ({ data }) => {
        dispatch({
          type: UserActionType.SET_USER_DATA,
          payload: data,
        });
      },
      onError: (error: IErrorResponse) => {
        errorHandler(error.response);
      },
    }
  );

  if (!open) {
    return null;
  }

  return (
    <Box
      sx={{
        width: "100%",
        backgroundColor: "#ffd700",
        borderRadius: 1,
        p: 0.75,
      }}
      display="flex"
      alignItems="center"
    >
      <Typography align="center" sx={{ flex: 1 }}>
        We have a new name! {ConfigConstant.OLD_APP_NAME} is now{" "}
        <b>{ConfigConstant.APP_NAME}</b>
      </Typography>
      <IconButton
        aria-label="open drawer"
        edge="start"
        onClick={() => mutateOnClose.mutate()}
        size="small"
        color="default"
      >
        <Close fontSize="medium" />
      </IconButton>
    </Box>
  );
};

export default NewNameUpdate;
