import axios, { AxiosResponse } from "axios";
import {
  IInteraction,
  IInteractions,
  IInteractionFormValues,
  IDirectAction,
  IDirectActionFormValues,
  IDirectActions,
  InteractionCategories,
  IInteractionCount,
  IInteractionStats,
} from "../models";
import { getActivityCategoryString, getInboxPrivacyQuery } from "../utils";

const activityCategoryString = getActivityCategoryString();

const createInteraction = (
  data: IInteractionFormValues
): Promise<AxiosResponse<IInteraction>> => {
  return axios.post("/inevitable/interactions/", data);
};

const fetchInteraction = (id: number): Promise<AxiosResponse<IInteraction>> => {
  return axios.get(`inevitable/interactions/${id}`);
};

const fetchAccountInteractions = (
  accountId: number,
  page: number,
  personId = "",
  campaignId = ""
): Promise<AxiosResponse<IInteractions>> => {
  const privacyQuery = getInboxPrivacyQuery(true);
  return axios.get(
    `inevitable/interactions/?person_id=${personId}&account_id=${accountId}&page=${page}&campaign_id=${campaignId}${privacyQuery}${activityCategoryString}`
  );
};

const fetchPersonInteractions = (
  accountId: number,
  personId: number,
  page: number,
  // inboxPrivacy: boolean,
  campaignId = ""
): Promise<AxiosResponse<IInteractions>> => {
  // const privacyQuery = getInboxPrivacyQuery(inboxPrivacy);
  return axios.get(
    `inevitable/interactions/?person_id=${personId}&account_id=${accountId}&page=${page}&campaign_id=${campaignId}`
  );
};

const fetchInteractionsDistinctOnContact = (
  accountId: number,
  page: number,
  campaignQuery: string,
  inboxPrivacy: boolean,
  search = "",
  filterQuery = ""
): Promise<AxiosResponse<IInteractions>> => {
  const privacyQuery = getInboxPrivacyQuery(inboxPrivacy);
  return axios.get(
    `/inevitable/interactions/distinct_on_contact/?page=${page}&contact_id__isnull=false&account_id=${accountId}&search=${search}&category=${InteractionCategories.message}&category=${InteractionCategories.inMail}${campaignQuery}${filterQuery}${privacyQuery}`
  );
};

const fetchInteractionDistinctOnPerson = (
  personId: number,
  campaignId: number
): Promise<AxiosResponse<IInteractions>> => {
  return axios.get(
    `/inevitable/interactions/distinct_on_contact/?person_id=${personId}&campaign_id=${campaignId}`
  );
};

const fetchStoppedInteractionOnPerson = (
  personId: number,
  campaignId: number
): Promise<AxiosResponse<IInteractionCount>> => {
  return axios.get(
    `/inevitable/interactions/count/?person_id=${personId}&campaign_id=${campaignId}&category=${InteractionCategories.sequence_stopped}`
  );
};

const fetchSequenceInteractionDistinct = (
  sequenceId: number,
  page: number
): Promise<AxiosResponse<IInteractions>> => {
  return axios.get(
    `/inevitable/interactions/distinct_on_contact/?page=${page}&page_size=1&sequence_id=${sequenceId}&person_id__isnull=false`
  );
};

const fetchPendingInvitations = (
  accountId: number,
  page: number,
  search = ""
): Promise<AxiosResponse<IInteractions>> => {
  return axios.get(
    `/inevitable/interactions/distinct_on_contact/?category=3&account_id=${accountId}&category=9&page=${page}&search=${search}&contact__active_connection=false&failed=false`
  );
};

const fetchPendingInvitation = (
  accountId: number,
  personId: number | undefined
): Promise<AxiosResponse<IInteractions>> => {
  return axios.get(
    `/inevitable/interactions/distinct_on_contact/?person_id=${personId}&account_id=${accountId}&category=3&category=9&contact__active_connection=false&failed=false`
  );
};

const fetchAllPendingInvitations = (
  accountId: number,
  queryString: string
): Promise<AxiosResponse<IInteractions>> => {
  return axios.get(
    `/inevitable/interactions/distinct_on_contact/?category=3&category=9&account_id=${accountId}&page=1&page_size=1000&${queryString}&contact__active_connection=false&failed=false`
  );
};

const fetchExistingDirectActionMessages = (
  accountId: number
): Promise<AxiosResponse<IInteractions>> => {
  return axios.get(
    `inevitable/interactions/?account_id=${accountId}&page=1&page_size=1&category=1&account_is_actor=true&direct_action_id__isnull=false`
  );
};

const markInteractionAsSeen = (
  id: number | undefined
): Promise<AxiosResponse<IInteraction["contact"]>> => {
  return axios.post("/inevitable/interactions/mark_as_seen/", {
    contact: id,
  });
};

const createDirectAction = (
  data: IDirectActionFormValues
): Promise<AxiosResponse<IDirectAction>> => {
  return axios.post(`/inevitable/direct_actions/`, data);
};

const fetchPersonDirectActions = (
  accountId: number,
  personId: number
): Promise<AxiosResponse<IDirectActions>> => {
  return axios.get(
    `/inevitable/direct_actions/?account_id=${accountId}&person_id=${personId}&status=W`
  );
};

const fetchConnected = (
  accountId: number,
  queryString: string
): Promise<AxiosResponse<IInteractions>> => {
  return axios.get(
    `inevitable/interactions/?account_id=${accountId}&page=1&page_size=1000&category=8&campaign_id__isnull=false&failed=false&${queryString}`
  );
};

const fetchConnectedCount = (
  accountId: number,
  queryString: string
): Promise<AxiosResponse<IInteractionCount>> => {
  return axios.get(
    `inevitable/interactions/count/?account_id=${accountId}&category=8&campaign_id__isnull=false&failed=false&${queryString}`
  );
};

const fetchMessaged = (
  accountId: number,
  queryString: string
): Promise<AxiosResponse<IInteractions>> => {
  return axios.get(
    `inevitable/interactions/?account_id=${accountId}&page=1&page_size=1000&category=1&category=15&account_is_actor=true&campaign_id__isnull=false&failed=false&${queryString}`
  );
};

const fetchMessagedCount = (
  accountId: number,
  queryString: string
): Promise<AxiosResponse<IInteractionCount>> => {
  return axios.get(
    `inevitable/interactions/count/?account_id=${accountId}&category=1&category=15&account_is_actor=true&campaign_id__isnull=false&failed=false&${queryString}`
  );
};

const fetchReplies = (
  accountId: number,
  queryString: string
): Promise<AxiosResponse<IInteractions>> => {
  return axios.get(
    `inevitable/interactions/?account_id=${accountId}&page=1&page_size=1000&category=1&account_is_actor=false&campaign_id__isnull=false&failed=false&${queryString}`
  );
};

const fetchRepliesCount = (
  accountId: number,
  queryString: string
): Promise<AxiosResponse<IInteractionCount>> => {
  const url = `inevitable/interactions/count/?account_id=${accountId}&page=1&page_size=1000&category=1&account_is_actor=false&campaign_id__isnull=false&failed=false&${queryString}`;
  return axios.get(url);
};

const fetchRequestOrMessageDistinctCount = (
  accountId: number,
  queryString: string
): Promise<AxiosResponse<IInteractionCount>> => {
  return axios.get(
    `inevitable/interactions/distinct_on_contact_count/?account_id=${accountId}&category=${InteractionCategories.message}&category=${InteractionCategories.connection_request}&account_is_actor=true&campaign_id__isnull=false&failed=false&${queryString}`
  );
};

// TODO: decide if to show only replies within campaign - &campaign_id__isnull=false
const fetchUnreadReplies = (
  accountId: number
): Promise<AxiosResponse<IInteractions>> => {
  return axios.get(
    `inevitable/interactions/?account_id=${accountId}&page=1&page_size=1000&category=1&account_is_actor=false&seen=false`
  );
};

const fetchRequests = (
  accountId: number,
  queryString: string
): Promise<AxiosResponse<IInteractions>> => {
  return axios.get(
    `inevitable/interactions/?account_id=${accountId}&page=1&page_size=1000&category=3&campaign_id__isnull=false&failed=false&${queryString}`
  );
};

const fetchRequestCount = (
  accountId: number,
  queryString: string
): Promise<AxiosResponse<IInteractionCount>> => {
  return axios.get(
    `inevitable/interactions/count/?account_id=${accountId}&category=3&campaign_id__isnull=false&failed=false&${queryString}`
  );
};

const fetchProfiles = (
  accountId: number,
  queryString: string
): Promise<AxiosResponse<IInteractions>> => {
  return axios.get(
    `inevitable/interactions/?account_id=${accountId}&page=1&page_size=1000&${queryString}&category=2&failed=false`
  );
};

const fetchProfilesCount = (
  accountId: number,
  queryString: string
): Promise<AxiosResponse<IInteractionCount>> => {
  return axios.get(
    `inevitable/interactions/count/?account_id=${accountId}&category=2&failed=false&${queryString}`
  );
};

const fetchCampaignSequenceTotalProspects = (
  accountId: number,
  queryString: string
): Promise<AxiosResponse<IInteractionCount>> => {
  return axios.get(
    `inevitable/interactions/distinct_on_contact_count/?account_id=${accountId}&failed=false${queryString}${activityCategoryString}`
  );
};

const fetchCampaignActivated = (
  campaignId: number
): Promise<AxiosResponse<IInteractions>> => {
  return axios.get(
    `inevitable/interactions/?page=1&page_size=1&campaign_id=${campaignId}&ordering=platform_created_at${activityCategoryString}`
  );
};

const fetchAccountTotalDistinctInteractions = (
  accountId: number
): Promise<AxiosResponse<IInteractionCount>> => {
  return axios.get(
    `inevitable/interactions/distinct_on_contact_count/?account_id=${accountId}&failed=false`
  );
};

const fetchAccountProcessedDistinctInteractions = (
  accountId: number
): Promise<AxiosResponse<IInteractionCount>> => {
  return axios.get(
    `inevitable/interactions/distinct_on_contact_count/?account_id=${accountId}${activityCategoryString}&failed=false`
  );
};

const fetchCampaignTotalDistinctInteractionsCount = (
  campaignId: number
): Promise<AxiosResponse<IInteractionCount>> => {
  return axios.get(
    `inevitable/interactions/distinct_on_contact_count/?campaign_id=${campaignId}&failed=false`
  );
};

const fetchCampaignProcessedDistinctInteractions = (
  campaignId: number
): Promise<AxiosResponse<IInteractionCount>> => {
  return axios.get(
    `inevitable/interactions/distinct_on_contact_count/?campaign_id=${campaignId}${activityCategoryString}&failed=false`
  );
};

const fetchCampaignSequenceReport = (
  campaignId: number,
  sequenceId: number,
  queryString: string
): Promise<AxiosResponse<IInteractionCount>> => {
  return axios.get(
    `inevitable/interactions/distinct_on_contact_count/?campaign_id=${campaignId}&sequence_id=${sequenceId}&failed=false&${queryString}`
  );
};

const fetchStoppedCount = (
  campaignId: number,
  sequenceId: number
): Promise<AxiosResponse<IInteractionCount>> => {
  return axios.get(
    `inevitable/interactions/distinct_on_contact_count/?campaign_id=${campaignId}&sequence_id=${sequenceId}&category=${InteractionCategories.sequence_stopped}`
  );
};

const fetchAllQueuedPeopleInCampaign = (
  campaignId: number
): Promise<AxiosResponse<IInteractions>> => {
  return axios.get(
    `inevitable/interactions/distinct_on_contact/?campaign_id=${campaignId}&category=14&page=1&page_size=1000`
  );
};

const fetchAccountStats = (
  accountId: number,
  queryString: string
): Promise<AxiosResponse<IInteractionStats>> => {
  return axios.get(
    `inevitable/interactions/stats/?account_id=${accountId}&failed=false&${queryString}`
  );
};

const InteractionService = {
  createInteraction,
  fetchInteraction,
  fetchAccountInteractions,
  fetchPersonInteractions,
  fetchSequenceInteractionDistinct,
  fetchInteractionDistinctOnPerson,
  fetchStoppedInteractionOnPerson,
  fetchInteractionsDistinctOnContact,
  fetchPendingInvitations,
  fetchPendingInvitation,
  fetchExistingDirectActionMessages,
  fetchAllPendingInvitations,
  markInteractionAsSeen,
  createDirectAction,
  fetchPersonDirectActions,
  fetchConnected,
  fetchConnectedCount,
  fetchMessaged,
  fetchMessagedCount,
  fetchReplies,
  fetchRepliesCount,
  fetchUnreadReplies,
  fetchRequests,
  fetchRequestCount,
  fetchRequestOrMessageDistinctCount,
  fetchProfiles,
  fetchProfilesCount,
  fetchCampaignActivated,
  fetchAccountTotalDistinctInteractions,
  fetchAccountProcessedDistinctInteractions,
  fetchCampaignTotalDistinctInteractionsCount,
  fetchCampaignProcessedDistinctInteractions,
  fetchCampaignSequenceReport,
  fetchStoppedCount,
  fetchAllQueuedPeopleInCampaign,
  fetchCampaignSequenceTotalProspects,
  fetchAccountStats,
};

export default InteractionService;
