/* eslint-disable react/require-default-props */
import React from "react";
import clsx from "clsx";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import LockIcon from "@mui/icons-material/Lock";
import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import RouterConstants from "core/routes/constants";
import ConfigConstant from "core/constants/ConfigConstant";
import Button from "ui-kit/atoms/Button";
import { useLocation } from "react-router-dom";
import { Box } from "@mui/material";

interface IAuthProps {
  children: React.ReactNode;
  title: string;
  subtitle?: string;
  action?: React.ReactElement;
  helper?: React.ReactElement;
  footer?: React.ReactElement;
  submitBtn?: string;
  banner?: React.ReactElement;
  disableForm?: boolean;
  onHandleSubmit?: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    height: "100vh",
  },
  box: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
  },
  authBox: {
    backgroundColor: theme.palette.background.default,
  },
  auth: {
    width: "100%",
    maxWidth: 500,
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(0, 4),
    },
  },
  header: {
    marginBottom: theme.spacing(12),
  },
  input: {
    backgroundColor: theme.palette.background.paper,
  },
  fields: {
    marginBottom: theme.spacing(6),
  },
  logoParent: {
    marginBottom: theme.spacing(7),
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: theme.spacing(2),
  },
  logoImg: {
    height: 28,
  },
  heading: {
    fontWeight: 700,
    fontSize: 32,
    marginBottom: theme.spacing(2),
  },
  helper: {
    marginTop: theme.spacing(4),
    color: theme.app.palette.action.color,
  },
  footer: {
    marginTop: theme.spacing(4),
    color: theme.app.palette.action.color,
  },
  bannerBox: {
    backgroundColor: theme.app.palette.primeSurface.dark,
  },
  startIcon: {
    position: "absolute",
    left: 0,
    marginLeft: 12,
    opacity: 0.35,
  },
}));

const AuthForm = ({
  children,
  title,
  subtitle,
  helper,
  action,
  banner,
  submitBtn,
  disableForm,
  onHandleSubmit,
  footer,
}: IAuthProps): React.ReactElement => {
  const classes = useStyles();
  const location = useLocation();

  return (
    <Grid container className={classes.container}>
      {ConfigConstant.APP_ID === 2 && location?.search === "?q=new" && (
        <Box sx={{ width: "100%", padding: "8px", backgroundColor: "#ffd700" }}>
          <Typography align="center">
            We have a new name! {ConfigConstant.OLD_APP_NAME} is now{" "}
            <b>{ConfigConstant.APP_NAME}</b>.
          </Typography>
        </Box>
      )}
      <Grid
        item
        xs={12}
        // md={banner ? 8 : 12}
        className={clsx(classes.box, classes.authBox)}
      >
        <div className={classes.auth}>
          <a href={RouterConstants.LANDING.INDEX}>
            <div className={classes.logoParent}>
              <img
                src={`/${ConfigConstant.DOMAIN_NAME}/logo.svg`}
                className={classes.logoImg}
                alt={`${ConfigConstant.APP_NAME} logo`}
              />
              <Typography color="textPrimary" variant="h6" fontWeight={700}>
                {ConfigConstant.APP_NAME}
              </Typography>
            </div>
          </a>

          <div className={classes.header}>
            <Typography
              color="textSecondary"
              className={classes.heading}
              variant="h4"
            >
              {title}
            </Typography>
            <Typography
              color="textSecondary"
              className={classes.heading}
              variant="h4"
            >
              {subtitle}
            </Typography>
            <Typography className={classes.helper} variant="body1">
              {helper}
            </Typography>
          </div>

          {disableForm ? (
            children
          ) : (
            <form autoComplete="off" onSubmit={onHandleSubmit}>
              <div className={classes.fields}>{children}</div>
              <Button
                startIcon={<LockIcon />}
                fullWidth
                variant="contained"
                color="primary"
                type="submit"
                size="large"
                classes={{ startIcon: classes.startIcon }}
              >
                {submitBtn}
              </Button>
            </form>
          )}

          {action && (
            <Typography
              variant="body2"
              component="p"
              className={classes.helper}
            >
              {action}
            </Typography>
          )}

          {footer && (
            <Typography
              className={classes.footer}
              variant="caption"
              component="p"
              color="textSecondary"
            >
              {footer}
            </Typography>
          )}
        </div>
      </Grid>
      {/* {banner} */}
    </Grid>
  );
};

export default AuthForm;
