import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import * as Sentry from "@sentry/react";
import AppBar from "core/routes/components/AppBar";
import FuseLimitAlert from "modules/Account/components/FuseLimitAlert";
import InviteCheckAlert from "modules/Account/components/InviteCheckAlert";
import ProxyAlert from "modules/Account/components/ProxyAlert";
import SyncAccount from "modules/Account/components/SyncAccount";
import { AccountContext } from "modules/Account/context";
// import HelpCenter from "modules/Dashboard/components/HelpCenter";
import NewNameUpdate from "modules/Account/components/NewNameUpdate";
import OnboardingBanner from "modules/Dashboard/components/OnboardingBanner";
import React from "react";
import AppContent from "ui-kit/components/Content";
import ErrorFallback from "../ErrorFallback";

const useStyles = makeStyles((theme: Theme) => ({
  content: {
    flexGrow: 1,
    width: `calc(100% - ${theme.app.constants.drawerWidth}px)`,
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(theme.app.constants.contentBottom - 4),
    [theme.breakpoints.up("md")]: {
      paddingTop: theme.spacing(8),
      paddingBottom: theme.spacing(theme.app.constants.contentBottom),
    },
  },
}));

interface ContentProps {
  children: React.ReactNode;
  disabledNavBar?: boolean;
  disableOnboarding?: boolean;
}

const Content = ({
  children,
  disabledNavBar,
  disableOnboarding,
}: ContentProps): React.ReactElement | null => {
  const classes = useStyles();

  const {
    account: { id: currentAccountId },
  } = React.useContext(AccountContext);

  const [drawerMobileOpen, setDrawerMobile] = React.useState(false);

  const handleDrawerMobileToggle = () => {
    setDrawerMobile(!drawerMobileOpen);
  };

  return (
    <Sentry.ErrorBoundary fallback={(props) => <ErrorFallback {...props} />}>
      <NewNameUpdate />
      <AppBar
        disabledNavBar={!!disabledNavBar}
        drawerMobileOpen={drawerMobileOpen}
        handleDrawerMobileToggle={handleDrawerMobileToggle}
      />

      <AppContent>
        <div className={classes.content}>
          <Sentry.ErrorBoundary
            fallback={(props) => <ErrorFallback {...props} />}
          >
            {!!currentAccountId && <SyncAccount />}
            {!!currentAccountId && <InviteCheckAlert />}
            {!!currentAccountId && <FuseLimitAlert />}
            {!!currentAccountId && <ProxyAlert />}
            {!!currentAccountId && !disableOnboarding && <OnboardingBanner />}
            {/* TODO: Add custom help center or some live chat system, currently just using chat as new site doesn't have a way for help center */}
            {/* <HelpCenter /> */}
            {children}
          </Sentry.ErrorBoundary>
        </div>
      </AppContent>
    </Sentry.ErrorBoundary>
  );
};

export default Content;
